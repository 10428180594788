//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Theme,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  createStyles,
  WithStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import Collapse from "@material-ui/core/Collapse";
import DeleteIcon from "@material-ui/icons/Delete";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter, RouteComponentProps } from "react-router";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";
import withDialog, {
  DialogProps,
} from "../../../../components/src/withDialog.web";
import { NumericFormat } from "react-number-format";

import CustomGrid from "../../../../components/src/CustomGrid.web";
import { LinkIcon } from "../assets";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import {
  StyledTableCell,
  TableRowCustomized,
} from "./CustomPackageCreationSummary.web";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { formatCurrency } from "./CustomPackageCreationSelectFeaturesPage.web";
import TernaryCheck from "../TernaryCheck.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const getComponentStyle = (theme: Theme): any => {
  return createStyles({
    columnsHeadingForGoal: {
      fontFamily: "Lato",
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      fontWeight: "bold",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    textFieldFormControlRoot: {
      fontSize: "1.125rem",
      "label + &": {
        marginTop: 30,
      },
    },
    textFieldOutLined: {
      transform: "translate(5px, -25px) scale(1)",
    },
    textFieldSubHeading: {
      fontSize: "1.2rem",
      color: theme.palette.text.secondary,
      fontWeight: 700,
    },
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    inputWidth: {
      width: "60%",
    },
    amoutInputWidth: {
      width: "60%",
      border: "1px solid",
      borderRadius: "0",
      marginBottom: "4px",
    },
    InputPadding: {
      padding: "5px",
    },
    adornedEnd: {
      paddingRight: "6px",
    },
    adornedStart: {
      paddingLeft: "6px",
    },
    dividerRoot: {
      width: "99%",
      margin: "0 auto",
    },
    containerTable: {
      width: "100%",
      borderRadius: "16px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    breadcumLink: {
      fontSize: "1.325rem",
      cursor: "pointer",
    },
    breadcumLastLink: {
      fontSize: "1.325rem",
    },
  });
};
export type Props = RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  WithStyles<typeof getComponentStyle> & {
    id: string;
    goToFirmPricingCustomization: () => void;
    goToFirmPricing: () => void;
    goToStep0: () => void;

    // Customizable Area Start
    // Customizable Area End
  };

const Schema = yup.object().shape({
  name: yup.string().required("This field is required."),
  description: yup.string().required("This field is required."),
  stratagic_rate: yup
    .number()
    .positive()
    .required("This field is required.")
    .test("is-decimal", "invalid value", (value) =>
      (value + "").match(/^\d+(\.\d{1,2})?$/)
    ),
  contract_period: yup.number().required("This field is required."),
  package_detail_link: yup.string(),
  value_margin: yup.string(),
  is_unfront_payment: yup.boolean(),
  unfront_payment_type: yup.string().when("is_unfront_payment", {
    is: (is_unfront_payment) => is_unfront_payment,
    then: yup.string().required("This field is required."),
  }),
  unfront_percentage: yup
    .string()
    .when(["is_unfront_payment", "unfront_payment_type"], {
      is: (is_unfront_payment, unfront_payment_type) =>
        is_unfront_payment && unfront_payment_type === "percentage",
      then: yup.string().required("This field is required."),
    }),
  unfront_lump_suump_amount: yup
    .string()
    .when(["is_unfront_payment", "unfront_payment_type"], {
      is: (is_unfront_payment, unfront_payment_type) =>
        is_unfront_payment && unfront_payment_type === "lumpsump",
      then: yup.string().required("This field is required."),
    }),
  custom_package_features: yup.array(
    yup.object({
      feature_id: yup.string(),
      tag: yup.string(),
      _destroy: yup.number().nullable(),
      new_id: yup.string(),
      feature_name: yup.string().required(),
      payment_period: yup.string().required(),
      order_number: yup.string(),
      unique_feature_id: yup.string(),
      payment_frequency: yup
        .string()
        .when("payment_period", {
          is: (payment_period) => payment_period === "Recurring",
          then: yup.string().required("This field is required."),
        })
        .min(1, "please select atleast one feature"),
      package_category_id: yup.string(),
      package_feature_id: yup.string(),
      hour: yup.string(),
      stratagic_rate: yup.string().when("hour", {
        is: (hour) => parseInt(hour) > 0,
        then: yup.string().required("This field is required."),
      }),
      default_cost: yup.string().when("hour", {
        is: (hour) => !!hour,
        then: yup.string().required("This field is required."),
      }),
      custom_package_bumps_attributes: yup.array(
        yup.object({
          bump_type: yup.string(),
          bump_name: yup.string().required(),
          bump_multiplier: yup.string().required(),
          bump_amount: yup.string().required(),
          total_bump_cost: yup.string(),
          pricing_bump_id: yup.string(),
          feature_name: yup.string(),
        })
      ),
    })
  ),
});

export class CustomPackageCreationPricing extends React.PureComponent {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {

    if (this.props.bumpList?.length == 0) {
      this.props.getBumps();
    } if (this.props.allFeatureListForPricingScreen?.length == 0) {
      this.props.getAllFeaturesForPriceingScreen();
    }
    this.init();
  }
  init = () => {
    if (this.props.isFromRoadMap && !this.props.isCreateCustomPackage) {
      if (
        this.props.defaultPackageTemplate &&
        this.props.defaultPackageTemplate !== "select"
      ) {
        this.init2();
      }
    }
  };
  init2 = () => {
    if (this.props.isCustomPackageTemplate) {
      if (this.props.selectedFeatureList.length == 0) {
        this.props.getAllFeaturesofSelectedCustomPackage();
      }
    } else {
      if (this.props.selectedFeatureList.length == 0) {
        this.props.getAllFeaturesofSelectedPackage();
      }
    }
  };

  calculateTotalPrice = (values) => {
    let totalContractValue = 0;
    let totalContractValueAfterRemovingUnfrontAmount = 0;
    values?.custom_package_features &&
      values?.custom_package_features.forEach((feature: any) => {
        let featuresPriceTotal = 0;
        const stratagic_rate =
          +feature?.stratagic_rate || +values?.stratagic_rate;
        const default_cost = +feature?.default_cost;
        const payment_period = feature?.payment_period;
        const payment_frequency = feature?.payment_frequency;
        const hour = +feature?.hour;
        if (!feature._destroy) {
          if (payment_period == "One Time") {
            featuresPriceTotal += this.props.getOneTimeFess(
              hour,
              stratagic_rate,
              default_cost
            );
          } else if (payment_period == "Recurring") {
            featuresPriceTotal += this.props.getRecurringFess(
              payment_frequency,
              hour,
              stratagic_rate,
              default_cost,
              values?.contract_period
            );
          }
          featuresPriceTotal += this.props.getBumpPrice(feature);

          totalContractValue += +featuresPriceTotal;
        }
      });
    totalContractValue += +values?.value_margin;
    totalContractValueAfterRemovingUnfrontAmount = +totalContractValue;
    if (values.is_unfront_payment) {
      if (values.unfront_payment_type == "percentage") {
        totalContractValueAfterRemovingUnfrontAmount =
          totalContractValue -
          totalContractValue * (+values.unfront_percentage / 100);
      } else if (values.unfront_payment_type == "lumpsump") {
        totalContractValueAfterRemovingUnfrontAmount =
          totalContractValue - +values.unfront_lump_suump_amount;
      }
    }
    return { totalContractValue, totalContractValueAfterRemovingUnfrontAmount };
  };
  getCustomPackageDetailError = (errors, touched, keyName) => {
    return errors[keyName] && touched[keyName] ? true : false;
  };
  getCustomPackageDetailErrorMsg = (errors, touched, keyName) => {
    return errors[keyName] && touched[keyName] ? errors[keyName] : "";
  };
  getCustomPackageDetailFeatureError = (errors, keyName, featureIndex) => {
    return errors?.custom_package_features?.length > 0 &&
      errors?.custom_package_features[featureIndex] &&
      errors?.custom_package_features[featureIndex][keyName]
      ? true
      : false;
  };
  getCustomPackageDetailBumpError = (
    errors,
    keyName,
    featureIndex,
    bumpIndex,
    arrayHelpers,
    feature
  ) => {
    const dindex = this.props.getCurrentIndex(arrayHelpers, feature);
    return (
      errors.custom_package_features &&
      errors.custom_package_features.length > 0 &&
      errors.custom_package_features[dindex] &&
      errors.custom_package_features[dindex]?.custom_package_bumps_attributes &&
      errors.custom_package_features[dindex]?.custom_package_bumps_attributes[
      bumpIndex
      ] &&
      errors.custom_package_features[dindex]?.custom_package_bumps_attributes[
      bumpIndex
      ][keyName]
    );
  };
  getFeatureTotal = (feature, values) => {
    let featuresPriceTotal = 0;
    const stratagic_rate = +feature?.stratagic_rate || +values?.stratagic_rate;
    const default_cost = +feature?.default_cost;
    const payment_period = feature?.payment_period;
    const payment_frequency = feature?.payment_frequency;
    const hour = +feature?.hour;
    if (payment_period == "One Time") {
      featuresPriceTotal += this.props.getOneTimeFess(
        hour,
        stratagic_rate,
        default_cost
      );
    } else if (payment_period == "Recurring") {
      featuresPriceTotal += this.props.getRecurringFess(
        payment_frequency,
        hour,
        stratagic_rate,
        default_cost,
        values?.contract_period
      );
    }
    featuresPriceTotal += this.props.getBumpPrice(feature);
    return featuresPriceTotal;
  };

  getFirmFinancialsIdealPrice = () => {
    return `$ ${formatCurrency.format(
      Math.round(
        this.props.default_strategic_price
          ? this.props.default_strategic_price || 0
          : this.props.default_firm_finance_strategic_price || 0
      )
    )}`;
  };

  renderPaymentTerms = (
    values,
    classes,
    handleChange,
    totalContractValue,
    totalContractValueAfterRemovingUnfrontAmount,
    setFieldValue
  ) => {
    return (
      <>
        <CustomGrid item xs={12} margin="1rem 0 1.5rem 0">
          <Typography color="primary" gutterBottom variant="h1">
            Payment Terms
          </Typography>
          <CustomTypography fontSize="0.9rem" color="textSecondary">
            You can add a default amount you would like to collect upfront for
            this package. You can use the ongoing fees found below to verify
            your pricing is correct.
          </CustomTypography>
        </CustomGrid>

        <CustomGrid item xs={6} width="45%" margin="0 0 3rem 2%">
          <CustomTypography fontSize="1.425rem">
            Contract Period: {values.contract_period} months
          </CustomTypography>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                name="is_unfront_payment"
                checked={values.is_unfront_payment}
                onChange={handleChange}
                color="primary"
              />
            }
            label="I want to collect upfront fees."
            labelPlacement="end"
          />
        </CustomGrid>
        <CustomGrid item xs={3} width="35%" margin="0 2% 3rem 0">
          <TernaryCheck
            condition={
              this.props.default_firm_finance_strategic_price ||
              this.props.default_strategic_price
            }
            trueComponent={
              <CustomTypography
                fontSize="1rem"
                margin="0 0 1rem 0"
                align="right"
              >
                Ideal price for this package is based on Firm Financials:
              </CustomTypography>
            }
            falseComponent={null}
          />
          <CustomTypography fontSize="1rem" fontWeight="bold" align="right">
            Total Contract Value:
          </CustomTypography>
        </CustomGrid>
        <CustomGrid item xs={3} width="14%" margin="0 0 3rem 0">
          <TernaryCheck
            condition={
              this.props.default_firm_finance_strategic_price ||
              this.props.default_strategic_price
            }
            trueComponent={
              <CustomTypography fontSize="1rem" margin="0 0 1rem 0">
                {this.getFirmFinancialsIdealPrice()}
              </CustomTypography>
            }
            falseComponent={null}
          />
          <CustomTypography fontSize="1rem">
            $ {formatCurrency.format(totalContractValue)}
          </CustomTypography>
        </CustomGrid>

        {/* 2nd row */}
        {values?.is_unfront_payment ? (
          <>
            <CustomGrid item xs={6} width="14%">
              <CustomTypography fontSize="1.425rem">
                Upfront fees
              </CustomTypography>
              <FormControlLabel
                value="percentage"
                control={
                  <Radio
                    checked={values.unfront_payment_type == "percentage"}
                    value="percentage"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("unfront_lump_suump_amount", "");
                    }}
                    name="unfront_payment_type"
                  />
                }
                label="Percentage Upfront"
              />
              <FormControlLabel
                value="lumpsump"
                control={
                  <Radio
                    checked={values.unfront_payment_type == "lumpsump"}
                    value="lumpsump"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("unfront_percentage", "");
                    }}
                    name="unfront_payment_type"
                  />
                }
                label="Lump Sum Upfront"
              />{" "}
            </CustomGrid>
            <CustomGrid item xs={6} width="18%" margin="0 20% 0 0">
              <CustomTypography fontSize="1.425rem" margin="0 0 0.5rem 0">
                Amount
              </CustomTypography>
              <NumericFormat
                value={values.unfront_percentage}
                decimalScale={2}
                displayType="input"
                onValueChange={this.props.handleNumberChange.bind(
                  this,
                  setFieldValue
                )}
                suffix="%"
                customInput={TextField}
                size="small"
                variant="outlined"
                margin="none"
                name="unfront_percentage"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.amoutInputWidth,
                    input: classes.InputPadding,
                    adornedEnd: classes.adornedEnd,
                  },
                }}
                disabled={values.unfront_payment_type !== "percentage"}
              />
              <NumericFormat
                value={values.unfront_lump_suump_amount}
                decimalScale={2}
                displayType="input"
                onValueChange={this.props.handleNumberChange.bind(
                  this,
                  setFieldValue
                )}
                isAllowed={this.props.handleUpFrontValue.bind(
                  this,
                  totalContractValue
                )}
                prefix="$"
                customInput={TextField}
                size="small"
                variant="outlined"
                margin="none"
                name="unfront_lump_suump_amount"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.amoutInputWidth,
                    input: classes.InputPadding,
                    adornedEnd: classes.adornedEnd,
                  },
                }}
                disabled={values.unfront_payment_type !== "lumpsump"}
              />
            </CustomGrid>
          </>
        ) : (
          <>
            <CustomGrid item xs={6} width="14%"></CustomGrid>
            <CustomGrid item xs={6} width="18%" margin="0 20% 0 0"></CustomGrid>
          </>
        )}

        <CustomGrid item xs={3} width="30%" margin="0 2% 0 0">
          <CustomTypography fontSize="1rem" margin="0 0 1rem 0" align="right">
            Ongoing Fees after upfront payment
          </CustomTypography>
          <CustomTypography fontSize="1rem" fontWeight="bold" align="right">
            Payment Terms
          </CustomTypography>
        </CustomGrid>
        <CustomGrid item xs={3} width="15%">
          <CustomTypography fontSize="1rem" margin="0 0 1rem 0">
            ${" "}
            {formatCurrency.format(
              Math.round(totalContractValueAfterRemovingUnfrontAmount)
            )}
          </CustomTypography>
          {this.props.isFromRoadMap && !this.props.isCreateCustomPackage ? (
            <FormControlLabel
              control={
                <Checkbox
                  name="is_monthly_payment_term"
                  checked={values.is_monthly_payment_term}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={`$ ${formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 12)
              )} / month`}
              labelPlacement="end"
            />
          ) : (
            <CustomTypography fontSize="1rem" margin="0 0 1rem 0">
              ${" "}
              {formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 12)
              )}{" "}
              / month
            </CustomTypography>
          )}
          {this.props.isFromRoadMap && !this.props.isCreateCustomPackage ? (
            <FormControlLabel
              control={
                <Checkbox
                  name="is_quarterly_payment_term"
                  checked={values.is_quarterly_payment_term}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={`$ ${formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 4)
              )} / quarter`}
              labelPlacement="end"
            />
          ) : (
            <CustomTypography fontSize="1rem" margin="0 0 1rem 0">
              ${" "}
              {formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 4)
              )}{" "}
              / quarter
            </CustomTypography>
          )}
          {this.props.isFromRoadMap && !this.props.isCreateCustomPackage ? (
            <FormControlLabel
              control={
                <Checkbox
                  name="is_yearly_payment_term"
                  checked={values.is_yearly_payment_term}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={`$ ${formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 1)
              )} / year`}
              labelPlacement="end"
            />
          ) : (
            <CustomTypography fontSize="1rem" margin="0 0 1rem 0">
              ${" "}
              {formatCurrency.format(
                Math.round(totalContractValueAfterRemovingUnfrontAmount / 1)
              )}{" "}
              / year
            </CustomTypography>
          )}
        </CustomGrid>
      </>
    );
  };

  renderDynamicField = (
    feature,
    featureIndex,
    errors,
    setFieldValue,
    values,
    arrayHelpers
  ) => {
    return (
      <Grid item xs={5}>
        <div
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          {feature.hour ? (
            <>
              <Typography variant="body1" color="textPrimary">
                {" "}
                Strategic Rate
              </Typography>
              <CustomTypography
                margin="0 0 0 0.75rem"
                variant="body1"
                color="textPrimary"
              >
                $
              </CustomTypography>
            </>
          ) : (
            <>
              <Typography variant="body1" color="textPrimary">
                {" "}
                Default Cost
              </Typography>
              <CustomTypography
                margin="0 0 0 0.75rem"
                variant="body1"
                color="textPrimary"
              >
                $
              </CustomTypography>
            </>
          )}
          {feature.hour ? (
            <NumericFormat
              displayType="input"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={this.props.handleNumberChangeForFeatures.bind(
                this,
                setFieldValue,
                feature,
                arrayHelpers,
                "stratagic_rate",
                false
              )}
              thousandSeparator
              customInput={TextField}
              style={{ flex: 1 }}
              margin="none"
              name={`custom_package_features[${featureIndex}].stratagic_rate`}
              value={feature.stratagic_rate}
              error={this.getCustomPackageDetailFeatureError.bind(
                this,
                errors,
                "stratagic_rate",
                featureIndex
              )()}
              variant="outlined"
              size="small"
            />
          ) : (
            <NumericFormat
              displayType="input"
              decimalScale={2}
              onValueChange={this.props.handleNumberChangeForFeatures.bind(
                this,
                setFieldValue,
                feature,
                arrayHelpers,
                "default_cost",
                false
              )}
              thousandSeparator
              customInput={TextField}
              style={{ flex: 1 }}
              margin="none"
              value={feature.default_cost}
              name={`custom_package_features[${featureIndex}].default_cost`}
              error={this.getCustomPackageDetailFeatureError.bind(
                this,
                errors,
                "default_cost",
                featureIndex
              )()}
              variant="outlined"
              size="small"
            />
          )}
        </div>
      </Grid>
    );
  };

  renderFeature = (feature) => {
    return (
      !!feature?.isNewFeature &&
      this.props.allFeatureListForPricingScreen?.map((feature1: any) => {
        return feature1?.attributes?.features?.data.map((feature2) => {
          return (
            <MenuItem key={feature2?.id} value={feature2?.id}>
              {feature2?.attributes?.feature_name}
            </MenuItem>
          );
        });
      })
    );
  };
  renderAddBumpButton = (
    custom_package_bumps_attributes,
    setFieldValue,
    values,
    featureIndex,
    arrayHelpers,
    feature
  ) => {
    return (
      custom_package_bumps_attributes.length == 0 && (
        <CustomTypography
          onClick={() => {
            const dindex = this.props.getCurrentIndex(arrayHelpers, feature);
            const custom_package_features = [...values.custom_package_features];
            const sub_category = [
              ...(custom_package_features[dindex]
                .custom_package_bumps_attributes || []),
            ];
            sub_category.push({
              bump_type: "business",
              bump_name: "",
              bump_name_new: "",
              bump_multiplier: 1,
              bump_amount: "",
              total_bump_cost: "",
              pricing_bump_id: "",
              feature_name: "",
            });
            custom_package_features[
              dindex
            ].custom_package_bumps_attributes = sub_category;
            setFieldValue("custom_package_features", custom_package_features);
          }}
          variant="body1"
          color="primary"
          fontWeight={700}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          +Add Pricing Bump
        </CustomTypography>
      )
    );
  };

  getFeature = (feature: any) => {
    return {
      feature_id: feature?.attributes?.feature_id || "",
      order_number: feature?.attributes?.order_number || "",
      unique_feature_id: feature?.attributes?.unique_feature_id || "",
      tag: feature?.attributes?.tag || "",
      feature_name: feature?.attributes?.feature_name_new || feature?.attributes?.feature_name || "",
      payment_period: feature?.attributes?.payment_period || "",
      payment_frequency: feature?.attributes?.payment_frequency || "",
      default_cost: feature?.attributes?.default_cost || "",
      package_category_id:
        feature?.attributes?.package_category?.category_id || "",
      package_feature_id: feature?.attributes?.id || "",
      hour: feature?.attributes?.hour || "",
      stratagic_rate: feature?.attributes?.strategic_rate || "",
      new_id: feature?.attributes?.new_id,
      _destroy: feature?.attributes?._destroy,
      custom_package_bumps_attributes: feature?.attributes.custom_package_bumps_attributes?.length ? feature?.attributes.custom_package_bumps_attributes :
        feature?.attributes?.custom_package_bumps?.data?.map((bump) => {
          return {
            ...bump?.attributes,
            bump_name_new: bump?.attributes?.bump_name,
            bump_name: bump?.attributes?.pricing_bump_id,
          };
        }) || [],
    };
  };
  getInitialValue = (featureList: any) => {
    return {
      name: this.props.name,
      description: this.props.description,
      stratagic_rate: this.props.stratagic_rate,
      contract_period: this.props.contract_period,
      package_detail_link: this.props.package_detail_link,
      value_margin: this.props.value_margin,
      custom_package_features: featureList.map(
        (item, index) => {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              order_number: item.attributes.order_number || index,
            },
          };
        }
      ).map(this.getFeature),
      is_unfront_payment: this.props.is_unfront_payment || false,
      unfront_payment_type: this.props.unfront_payment_type,
      unfront_percentage: this.props.unfront_percentage || "",
      unfront_lump_suump_amount: this.props.unfront_lump_suump_amount || "",
      is_monthly_payment_term: this.props.is_monthly_payment_term || false,
      is_quarterly_payment_term: this.props.is_quarterly_payment_term || false,
      is_yearly_payment_term: this.props.is_yearly_payment_term || false,
    };
  };
  getNewFeature = (allFeatureList, slectedfeatlist, customfeature) => {
    let feature = null;
    feature = allFeatureList.find((pkg) => {
      return pkg.id == customfeature.package_feature_id;
    });
    if (!feature) {
      feature = slectedfeatlist?.find((pkg) => {
        return pkg?.id == customfeature.package_feature_id;
      });
    }
    return feature;
  };
  getFeatureList = (slectedfeatlist, customList) => {
    const featureList = [];
    let allFeatureList = this.props.allFeatureListForPricingScreen.map((aa) => {
      return aa?.attributes?.features?.data;
    });
    allFeatureList = [].concat.apply([], allFeatureList);
    if (customList.length > 0) {
      for (const customfeature of customList) {
        const feature = this.getNewFeature(
          allFeatureList,
          slectedfeatlist,
          customfeature
        );
        if (feature) {
          featureList.push({
            ...feature,
            attributes: {
              ...feature.attributes,
              ...customfeature,
              feature_name:
                customfeature?.feature_name_new || customfeature?.feature_name,
            },
          });
        }
      }
    } else {
      return slectedfeatlist;
    }
    return featureList;
  };

  renderFeatureName = (
    feature,
    featureIndex,
    errors,
    setFieldValue,
    handleChange,
    classes,
    arrayHelpers
  ) => {
    return feature?.isNewFeature ? (
      <Autocomplete
        fullWidth
        noOptionsText="No feature found"
        name={`custom_package_features[${featureIndex}].feature_name`}
        value={this.props
          .getFeaturesForAutoComplete()
          .find((aa) => aa?.attributes?.id == feature.package_feature_id)}
        error={this.getCustomPackageDetailFeatureError.bind(
          this,
          errors,
          "feature_name",
          featureIndex
        )()}
        disableClearable
        options={this.props.getFeaturesForAutoComplete(
          arrayHelpers.form.values.custom_package_features
        )}
        getOptionLabel={(item) => {
          return item?.attributes?.feature_name;
        }}
        onChange={this.props.handleNewFeature.bind(
          this,
          feature,
          setFieldValue,
          handleChange,
          this.props.isFromRoadMap,
          arrayHelpers
        )}
        classes={{
          inputRoot: classes.autoCompletedRadius,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Search.."
            margin="none"
            size="small"
            variant="outlined"
            InputProps={{
              ...params?.InputProps,
            }}
          />
        )}
      />
    ) : (
      <TextField
        name={`custom_package_features[${featureIndex}].feature_name`}
        value={feature.feature_name_new || feature.feature_name}
        error={this.getCustomPackageDetailFeatureError.bind(
          this,
          errors,
          "feature_name",
          featureIndex
        )()}
        onChange={this.props.handleNewFeature.bind(
          this,
          feature,
          setFieldValue,
          handleChange,
          this.props.isFromRoadMap,
          arrayHelpers
        )}
        variant="outlined"
        size="small"
        select={!!feature?.isNewFeature}
        multiline
        InputProps={{
          margin: "none",
        }}
        SelectProps={{
          displayEmpty: true,
          renderValue: !!feature.feature_name
            ? undefined
            : () => "Select Feature",
        }}
      >
        {this.renderFeature(feature)}
      </TextField>
    );
  };

  renderFeatures = (
    feature,
    afeatureIndex,
    values,
    handleChange,
    setFieldValue,
    errors,
    arrayHelpers
  ) => {
    const { classes } = this.props;
    const featuresPriceTotal = this.getFeatureTotal(feature, values);
    const custom_package_bumps_attributes =
      feature?.custom_package_bumps_attributes || [];
    const featureIndex = this.props.getCurrentIndex(arrayHelpers, feature)
    return (
      <Draggable
        draggableId={(feature?.unique_feature_id || featureIndex) + ""}
        index={featureIndex}
        key={feature?.unique_feature_id || featureIndex}
      >
        {(selectedFeatureDraggableProvided) => {
          return (
            <Grid
              key={feature?.package_feature_id || featureIndex}
              {...selectedFeatureDraggableProvided.draggableProps}
              {...selectedFeatureDraggableProvided.dragHandleProps}
              ref={selectedFeatureDraggableProvided.innerRef}
              xs={12}
              item
            >
              <Grid container justifyContent="center">
                <Grid xs={12} item>
                  <Grid
                    style={{
                      background: "#F9F9F9",
                      padding: "8px 16px",
                      borderRadius: "10px",
                    }}
                    container
                    alignItems="center"
                  >
                    <CustomGrid width="29%" xs={4} item>
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        {this.renderFeatureName(
                          feature,
                          featureIndex,
                          errors,
                          setFieldValue,
                          handleChange,
                          classes,
                          arrayHelpers
                        )}

                        {this.renderAddBumpButton(
                          custom_package_bumps_attributes,
                          setFieldValue,
                          values,
                          featureIndex,
                          arrayHelpers,
                          feature
                        )}
                      </div>
                    </CustomGrid>
                    <CustomGrid xs={4} width="46%" item>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <TextField
                            select
                            SelectProps={{
                              displayEmpty: true,
                              renderValue: !!feature.payment_frequency
                                ? undefined
                                : () => "Select",
                            }}
                            size="small"
                            name={`custom_package_features[${featureIndex}].payment_frequency`}
                            error={this.getCustomPackageDetailFeatureError.bind(
                              this,
                              errors,
                              "payment_frequency",
                              featureIndex
                            )()}
                            disabled={feature.payment_period == "One Time"}
                            value={feature.payment_frequency}
                            onChange={(event) => {
                              const dindex = this.props.getCurrentIndex(
                                arrayHelpers,
                                feature
                              );
                              setFieldValue(
                                `custom_package_features[${dindex}].payment_frequency`,
                                event.target.value
                              );
                            }}
                            fullWidth
                            variant="outlined"
                          >
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Quarterly">Quarterly</MenuItem>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                          </TextField>
                        </Grid>
                        {this.renderDynamicField(
                          feature,
                          featureIndex,
                          errors,
                          setFieldValue,
                          values,
                          arrayHelpers
                        )}
                        <Grid item xs={3}>
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body1" color="textPrimary">
                              {" "}
                              Hour
                            </Typography>
                            <NumericFormat
                              displayType="input"
                              onValueChange={this.props.handleNumberChangeForFeatures.bind(
                                this,
                                setFieldValue,
                                feature,
                                arrayHelpers,
                                "hour",
                                true
                              )}
                              thousandSeparator
                              decimalScale={0}
                              customInput={TextField}
                              style={{ flex: 1 }}
                              margin="none"
                              value={feature.hour}
                              name={`custom_package_features[${featureIndex}].hour`}
                              error={this.getCustomPackageDetailFeatureError.bind(
                                this,
                                errors,
                                "hour",
                                featureIndex
                              )()}
                              variant="outlined"
                              size="small"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CustomGrid>
                    <CustomGrid width="13%" xs={2} item>
                      <Typography color="textSecondary" variant="h3">
                        ${" "}
                        {formatCurrency.format(Math.round(featuresPriceTotal))}
                      </Typography>
                    </CustomGrid>
                    <CustomGrid width={"10%"} xs={2} item>
                      <TextField
                        select
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: !!feature.payment_period
                            ? undefined
                            : () => "Select",
                        }}
                        size="small"
                        name={`custom_package_features[${featureIndex}].payment_period`}
                        error={this.getCustomPackageDetailFeatureError.bind(
                          this,
                          errors,
                          "payment_period",
                          featureIndex
                        )()}
                        value={feature.payment_period}
                        onChange={(event) => {
                          const dindex = this.props.getCurrentIndex(
                            arrayHelpers,
                            feature
                          );
                          setFieldValue(
                            `custom_package_features[${dindex}].payment_period`,
                            event.target.value
                          );
                          if (event.target.value == "One Time") {
                            setFieldValue(
                              `custom_package_features[${dindex}].payment_frequency`,
                              ""
                            );
                            setFieldValue(
                              `custom_package_features[${dindex}].hour`,
                              ""
                            );
                          }
                        }}
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value="Recurring">Recurring</MenuItem>
                        <MenuItem value="One Time">One Time</MenuItem>
                      </TextField>
                    </CustomGrid>
                    <CustomGrid width={"2%"} xs={2} item>
                      <IconButton
                        id="deleteFeature"
                        onClick={this.props.deleteFeature.bind(
                          this,
                          arrayHelpers,
                          featureIndex,
                          feature,
                          setFieldValue,
                          this.props.isFromRoadMap
                        )}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </CustomGrid>
                  </Grid>
                </Grid>
                <CustomGrid margin="1rem 0 0 0" xs={12} item>
                  <Collapse
                    in={feature?.custom_package_bumps_attributes?.length > 0}
                  >
                    <TableContainer className={classes.containerTable}>
                      <Table
                        style={{ width: "100%" }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              color={"#f9f9f9"}
                              width="53%"
                              className={classes.columnsHeadingForGoal}
                            >
                              Bump Type
                            </StyledTableCell>
                            <StyledTableCell
                              color={"#f9f9f9"}
                              width="15%"
                              className={classes.columnsHeadingForGoal}
                            >
                              Bump Multiplier
                            </StyledTableCell>
                            <StyledTableCell
                              color={"#f9f9f9"}
                              width="15%"
                              className={classes.columnsHeadingForGoal}
                            >
                              Bump Amount
                            </StyledTableCell>
                            <StyledTableCell
                              color={"#f9f9f9"}
                              width="15%"
                              className={classes.columnsHeadingForGoal}
                            >
                              Bump Cost
                            </StyledTableCell>
                            <StyledTableCell
                              color={"#f9f9f9"}
                              width="2%"
                              className={classes.columnsHeadingForGoal}
                            ></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {custom_package_bumps_attributes?.map(
                            (bump, bumpIndex) => {
                              return (
                                <TableRowCustomized key={bump.id}>
                                  <StyledTableCell>
                                    <TextField
                                      select
                                      SelectProps={{
                                        displayEmpty: true,
                                        renderValue: !!feature
                                          ?.custom_package_bumps_attributes[
                                          bumpIndex
                                        ].bump_name
                                          ? undefined
                                          : () => "Select",
                                      }}
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      style={{
                                        width: "50%",
                                      }}
                                      value={
                                        feature
                                          ?.custom_package_bumps_attributes[
                                          bumpIndex
                                        ].bump_name
                                      }
                                      error={this.getCustomPackageDetailBumpError.bind(
                                        this,
                                        errors,
                                        "bump_name",
                                        featureIndex,
                                        bumpIndex,
                                        arrayHelpers,
                                        feature
                                      )()}
                                      onChange={(event) => {
                                        const dindex = this.props.getCurrentIndex(
                                          arrayHelpers,
                                          feature
                                        );
                                        const selectedBump = this.props.bumpList.find(
                                          (bump) => {
                                            return (
                                              bump.id == event.target.value
                                            );
                                          }
                                        );
                                        setFieldValue(
                                          `custom_package_features[${dindex}].custom_package_bumps_attributes[${bumpIndex}].bump_amount`,
                                          +selectedBump?.attributes?.bump_amount
                                        );
                                        setFieldValue(
                                          `custom_package_features[${dindex}].custom_package_bumps_attributes[${bumpIndex}].bump_name_new`,
                                          selectedBump?.attributes?.bump_name
                                        );
                                        setFieldValue(
                                          `custom_package_features[${dindex}].custom_package_bumps_attributes[${bumpIndex}].bump_name`,
                                          event.target.value
                                        );
                                      }}
                                      name={`custom_package_features[${featureIndex}].custom_package_bumps_attributes[${bumpIndex}].bump_name`}
                                    >
                                      {this.props.bumpList?.map((bump) => {
                                        return (
                                          <MenuItem
                                            key={bump.id}
                                            value={bump.id}
                                          >
                                            {bump?.attributes?.bump_name}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {" "}
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "4px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography>X</Typography>
                                      <NumericFormat
                                        displayType="input"
                                        onValueChange={this.props.handleNumberChangeForFeatureBump.bind(
                                          this,
                                          setFieldValue,
                                          feature,
                                          arrayHelpers,
                                          "bump_multiplier",
                                          bumpIndex
                                        )}
                                        thousandSeparator
                                        customInput={TextField}
                                        style={{ flex: 1 }}
                                        margin="none"
                                        value={
                                          feature
                                            ?.custom_package_bumps_attributes[
                                            bumpIndex
                                          ].bump_multiplier
                                        }
                                        name={`custom_package_features[${featureIndex}].custom_package_bumps_attributes[${bumpIndex}].bump_multiplier`}
                                        error={this.getCustomPackageDetailBumpError.bind(
                                          this,
                                          errors,
                                          "bump_multiplier",
                                          featureIndex,
                                          bumpIndex,
                                          arrayHelpers,
                                          feature
                                        )()}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                          classes: {
                                            root: classes.inputWidth,
                                          },
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {" "}
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "4px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography>$</Typography>
                                      <NumericFormat
                                        displayType="input"
                                        onValueChange={this.props.handleNumberChangeForFeatureBump.bind(
                                          this,
                                          setFieldValue,
                                          feature,
                                          arrayHelpers,
                                          "bump_amount",
                                          bumpIndex
                                        )}
                                        thousandSeparator
                                        customInput={TextField}
                                        style={{ flex: 1 }}
                                        margin="none"
                                        value={
                                          feature
                                            ?.custom_package_bumps_attributes[
                                            bumpIndex
                                          ].bump_amount
                                        }
                                        name={`custom_package_features[${featureIndex}].custom_package_bumps_attributes[${bumpIndex}].bump_amount`}
                                        error={this.getCustomPackageDetailBumpError.bind(
                                          this,
                                          errors,
                                          "bump_amount",
                                          featureIndex,
                                          bumpIndex,
                                          arrayHelpers,
                                          feature
                                        )()}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                          classes: {
                                            root: classes.inputWidth,
                                          },
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    ${" "}
                                    {formatCurrency.format(
                                      Math.round(
                                        (feature
                                          ?.custom_package_bumps_attributes[
                                          bumpIndex
                                        ].bump_amount || 0) *
                                        (feature
                                          ?.custom_package_bumps_attributes[
                                          bumpIndex
                                        ].bump_multiplier || 0)
                                      )
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton>
                                      <DeleteIcon
                                        onClick={() => {
                                          const dindex = this.props.getCurrentIndex(
                                            arrayHelpers,
                                            feature
                                          );
                                          const custom_package_features = [
                                            ...values.custom_package_features,
                                          ];
                                          const custom_package_bumps_attributes = [
                                            ...(custom_package_features[dindex]
                                              .custom_package_bumps_attributes ||
                                              []),
                                          ];
                                          custom_package_bumps_attributes.splice(
                                            bumpIndex,
                                            1
                                          );
                                          custom_package_features[
                                            dindex
                                          ].custom_package_bumps_attributes = custom_package_bumps_attributes;
                                          setFieldValue(
                                            "custom_package_features",
                                            custom_package_features
                                          );
                                        }}
                                        color="error"
                                      />
                                    </IconButton>
                                  </StyledTableCell>
                                </TableRowCustomized>
                              );
                            }
                          )}

                          <TableRowCustomized>
                            <StyledTableCell colSpan={5} align="center">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  const dindex = this.props.getCurrentIndex(
                                    arrayHelpers,
                                    feature
                                  );
                                  const custom_package_features = [
                                    ...values.custom_package_features,
                                  ];
                                  const sub_category = [
                                    ...(custom_package_features[dindex]
                                      .custom_package_bumps_attributes || []),
                                  ];
                                  sub_category.push({
                                    bump_type: "business",
                                    bump_name: "",
                                    bump_name_new: "",
                                    bump_multiplier: 1,
                                    bump_amount: "",
                                    total_bump_cost: "",
                                    pricing_bump_id: "",
                                    feature_name: "",
                                  });
                                  custom_package_features[
                                    dindex
                                  ].custom_package_bumps_attributes = sub_category;
                                  setFieldValue(
                                    "custom_package_features",
                                    custom_package_features
                                  );
                                }}
                              >
                                +Add Bump
                              </Button>
                            </StyledTableCell>
                          </TableRowCustomized>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </CustomGrid>
              </Grid>
            </Grid>
          );
        }}
      </Draggable>
    );
  };
  addNewFeature = (arrayHelpers) => {
    arrayHelpers.push({
      feature_id: "",
      tag: "",
      feature_name: "",
      payment_period: "",
      payment_frequency: "",
      default_cost: "",
      package_category_id: "",
      package_feature_id: "",
      hour: "",
      stratagic_rate: "",
      custom_package_bumps_attributes: [],
      isNewFeature: true,
      order_number: arrayHelpers.form.values.custom_package_features.length + 1
    });
  };
  submitForm = (values) => {

    const custom_package_features = values?.custom_package_features?.filter(
      (aa) => {
        return !aa?._destroy;
      }
    );
    if (!custom_package_features || custom_package_features.length === 0) {
      this.props.showToast({
        type: "error",
        message: "Please select at least one feature",
      });
      return;
    }

    if (
      this.props.isFromRoadMap &&
      !values.is_monthly_payment_term &&
      !values.is_quarterly_payment_term &&
      !values.is_yearly_payment_term &&
      !this.props.isCreateCustomPackage
    ) {
      this.props.showToast({
        type: "error",
        message: "Please select at least one payment term",
      });
      return;
    }
    if (this.props.isDuplicate) {
      values.is_duplicated  = true
      this.props.setState({
        isCreateDuplicatePackage : false,
        isEditRoadMapPackageTemplate : false,
        isCreateCustomPackage : true
      })
    }
    else{
      values.is_duplicated = this.props.selectedDuplicate
    }
    this.props.setFeaturePrice(values);
  };

  disableAddFeature = (arrayHelpers) => {
    const custom_package_features =
      arrayHelpers.form.values.custom_package_features;
    return (
      custom_package_features.filter((d) => {
        return d?.isNewFeature && !d?.feature_name;
      })?.length > 0
    );
  };

  render() {
    const { classes } = this.props;
    const featureList = this.getFeatureList(
      this.props.selectedFeatureList,
      this.props.custom_package_features
    ).sort(this.props.sortFeature);
    return (
      <Formik
        initialValues={this.getInitialValue(featureList)}
        validationSchema={Schema}
        enableReinitialize={!!this.props.isFromRoadMap}
        onSubmit={this.submitForm.bind(this)}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            errors,
            touched,
            isValid,
          } = formikProps;
          const {
            totalContractValue,
            totalContractValueAfterRemovingUnfrontAmount,
          } = this.calculateTotalPrice(values);
          return (
            <Form noValidate id="pricingform" autocomplete="off">
              <Grid container style={{ marginBottom: "36px" }} spacing={3}>
                {!this.props.isFromRoadMap && (
                  <Grid xs={12} item>
                    <Breadcrumbs separator=">" aria-label="breadcrumb">
                      <Typography
                        className={classes.breadcumLink}
                        onClick={this.props.goToFirmPricingCustomization}
                        color="primary"
                      >
                        Pricing Customization
                      </Typography>
                      <Typography
                        className={classes.breadcumLink}
                        onClick={this.props.goToFirmPricing}
                        color="primary"
                      >
                        Firm Pricing
                      </Typography>
                      {!this.props.packageType && (
                        <Typography
                          onClick={this.props.goToStep0}
                          className={classes.breadcumLink}
                          color="primary"
                        >
                          Create Custom Package
                        </Typography>
                      )}

                      <Typography
                        onClick={this.props.goToStep1.bind(this, values)}
                        className={classes.breadcumLink}
                        color="primary"
                      >
                        Select Custom Package Features
                      </Typography>
                      <Typography
                        className={classes.breadcumLastLink}
                        color="textSecondary"
                      >
                        Set Custom Package Pricing
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid xs={12} md={5}>
                      <Grid container spacing={4}>
                        <Grid xs={12} item>
                          <CustomTypography
                            color="textSecondary"
                            fontWeight={600}
                            fontSize="1.125rem"
                          >
                            Name
                          </CustomTypography>
                          <TextField
                            variant="outlined"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            error={this.getCustomPackageDetailError(
                              errors,
                              touched,
                              "name"
                            )}
                            helperText={this.getCustomPackageDetailErrorMsg(
                              errors,
                              touched,
                              "name"
                            )}
                            onBlur={handleBlur}
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.textFieldFormControlRoot,
                                outlined: classes.textFieldOutLined,
                              },
                              shrink: false,
                            }}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTypography
                            color="textSecondary"
                            fontWeight={600}
                            fontSize="1.125rem"
                          >
                            Description
                          </CustomTypography>
                          <TextField
                            multiline
                            minRows={6}
                            InputLabelProps={{
                              classes: {
                                root: classes.textFieldFormControlRoot,
                                outlined: classes.textFieldOutLined,
                              },
                              shrink: false,
                            }}
                            fullWidth
                            variant="outlined"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={this.getCustomPackageDetailError(
                              errors,
                              touched,
                              "description"
                            )}
                            helperText={this.getCustomPackageDetailErrorMsg(
                              errors,
                              touched,
                              "description"
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Grid container spacing={1}>
                        <Grid xs={8} item>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={classes.textFieldSubHeading}
                              >
                                Strategic Rate
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <NumericFormat
                                displayType="input"
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                onValueChange={this.props.handleStratagicRateChange.bind(
                                  this,
                                  values,
                                  setFieldValue
                                )}
                                thousandSeparator
                                customInput={TextField}
                                id="customPricingStratagicRate"
                                variant="outlined"
                                margin="none"
                                fullWidth
                                name="stratagic_rate"
                                value={values.stratagic_rate}
                                onBlur={handleBlur}
                                error={this.getCustomPackageDetailError(
                                  errors,
                                  touched,
                                  "stratagic_rate"
                                )}
                                helperText={this.getCustomPackageDetailErrorMsg(
                                  errors,
                                  touched,
                                  "stratagic_rate"
                                )}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Per Hour
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={8} item>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={classes.textFieldSubHeading}
                              >
                                Contract Period
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <NumericFormat
                                value={values.contract_period}
                                decimalScale={0}
                                displayType="input"
                                onValueChange={this.props.handleNumberChange.bind(
                                  this,
                                  setFieldValue
                                )}
                                thousandSeparator
                                customInput={TextField}
                                size="small"
                                variant="outlined"
                                margin="none"
                                name="contract_period"
                                id="contract_period_custom_package"
                                onBlur={handleBlur}
                                error={this.getCustomPackageDetailError(
                                  errors,
                                  touched,
                                  "contract_period"
                                )}
                                helperText={this.getCustomPackageDetailErrorMsg(
                                  errors,
                                  touched,
                                  "contract_period"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Months
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} item>
                          <Grid container spacing={1}>
                            <Grid xs={12} item>
                              <Typography color="primary" variant="h1">
                                Package Details Link
                              </Typography>
                            </Grid>
                            <Grid xs={12} item>
                              <CustomTypography
                                color="textPrimary"
                                fontWeight={600}
                                variant="body1"
                              >
                                If you would like to link a PDF or any other
                                details about this package you can insert a link
                                here.
                              </CustomTypography>
                            </Grid>
                            <Grid xs={12} item>
                              <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                name="package_detail_link"
                                value={values.package_detail_link}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        height={12}
                                        width={12}
                                        src={LinkIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h1">
                    Engagement Features
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DragDropContext
                    onDragEnd={this.props.onFeatureDragEndForPricing.bind(
                      this,
                      values,
                      setFieldValue
                    )}
                  >
                    <Droppable droppableId="selectedfeaturesDroppable">
                      {(selectedfeaturesDroppableProvided) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            ref={selectedfeaturesDroppableProvided.innerRef}
                            {...selectedfeaturesDroppableProvided.droppableProps}
                          >
                            <Grid xs={12} item>
                              <Grid
                                container
                                style={{
                                  background: "#F6FAFB",
                                  padding: "16px",
                                  borderRadius: "10px",
                                }}
                              >
                                <CustomGrid width="29%" xs={4} item>
                                  <Typography color="primary" variant="h3">
                                    Feature Name
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid xs={4} width="46%" item>
                                  <Typography color="primary" variant="h3">
                                    Feature Frequency
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid width="13%" xs={2} item>
                                  <Typography color="primary" variant="h3">
                                    Total Feature Cost
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid width="10%" xs={2} item>
                                  <Typography color="primary" variant="h3">
                                    Price Details
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid width="2%" xs={2} item></CustomGrid>
                              </Grid>
                            </Grid>

                            <FieldArray
                              name="custom_package_features"
                              render={(arrayHelpers) => {
                                return (
                                  <>
                                    {values?.custom_package_features
                                      .filter((a) => {
                                        return !a?._destroy;
                                      })
                                      .map((feature, index) => {
                                        return this.renderFeatures(
                                          feature,
                                          index,
                                          values,
                                          handleChange,
                                          setFieldValue,
                                          errors,
                                          arrayHelpers
                                        );
                                      })}

                                    <CustomGrid
                                      xs={2}
                                      width="15%"
                                      margin="1rem 0 0 85%"
                                      item
                                    >
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        disabled={this.disableAddFeature(
                                          arrayHelpers
                                        )}
                                        color="primary"
                                        id="addFeatureButton"
                                        onClick={this.addNewFeature.bind(
                                          this,
                                          arrayHelpers
                                        )}
                                      >
                                        + Add Features
                                      </Button>
                                    </CustomGrid>
                                  </>
                                );
                              }}
                            ></FieldArray>
                          </Grid>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    style={{
                      background: "#EAF2F4",
                      padding: "2.5rem",
                      borderRadius: "10px",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                    container
                  >
                    <Grid xs={10} item>
                      <CustomTypography
                        color="textSecondary"
                        fontWeight={600}
                        fontSize="1.5rem"
                        margin="0 0 1rem 0"
                      >
                        Value Margin
                      </CustomTypography>
                      <CustomTypography
                        color="textSecondary"
                        fontWeight={600}
                        fontSize="1.1rem"
                      >
                        This amount captures additional value realized through
                        this package
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <NumericFormat
                        value={values.value_margin}
                        decimalScale={2}
                        displayType="input"
                        onValueChange={this.props.handleNumberChange.bind(
                          this,
                          setFieldValue
                        )}
                        prefix="$"
                        thousandSeparator
                        customInput={TextField}
                        size="small"
                        variant="outlined"
                        margin="none"
                        name="value_margin"
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {this.renderPaymentTerms(
                  values,
                  classes,
                  handleChange,
                  totalContractValue,
                  totalContractValueAfterRemovingUnfrontAmount,
                  setFieldValue
                )}

                <CustomGrid width="17%" margin="2rem 0 0 83%" item xs={2}>
                  <Button
                    variant="contained"
                    id="pricingSubmit"
                    fullWidth
                    disabled={!isValid}
                    type="submit"
                    color="primary"
                  >
                    {this.props.isDuplicate ? "Save & Copy" : "Save & Continue"}
                  </Button>
                </CustomGrid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const CustomPackageCreationPricingWithRouter = withRouter(
  CustomPackageCreationPricing
);
const CustomPackageCreationPricingWithToast = withToast(
  CustomPackageCreationPricingWithRouter
);
const CustomPackageCreationPricingWithLoader = withLoader(
  CustomPackageCreationPricingWithToast
);
const CustomPackageCreationPricingWithAlertBox = withAlertBox(
  CustomPackageCreationPricingWithLoader
);
const CustomPackageCreationPricingWithDialogBox = withDialog(
  CustomPackageCreationPricingWithAlertBox
);

export default withStyles((theme: Theme) => getComponentStyle(theme))(
  CustomPackageCreationPricingWithDialogBox
);
